import { ChevronRightIcon } from '@heroicons/react/outline';
import { classNames } from 'app/utils/classNames';
import React, {
  forwardRef,
  LegacyRef,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import {
  ENTER_KEY,
  ESCAPE_KEY,
  DELETE,
  ARROW_DOWN,
  ARROW_UP,
} from '../constants/actionList';

import { Analytics, EventTypes } from 'app/utils/analytics';
import { ITodoItemProps } from '../interfaces/ActionList';
import TextareaAutosize from 'react-textarea-autosize';
import _ from 'lodash';
import DatePicker from 'react-datepicker';
import { friendlyDateFormat } from 'app/utils/stringToFormatDate';

const ActionItem = ({
  todo,
  editing,
  prefix,
  index,
  dueDateSetable,
  showDueDate = dueDateSetable,
  editable,
  completable,
  onToggle = () => {},
  onDestroy = () => {},
  onAddActionItem = () => {},
  onSave = () => {},
  onEdit = () => {},
  onCancel = () => {},
}: ITodoItemProps) => {
  const editField = useRef(null);
  const isClick = useRef(false);
  const id = 'action-item' + Math.ceil(Math.random() * 10000);
  const [editText, setEditText] = useState<string>(todo.title);
  const [due, setDue] = useState<Date>(todo.due ? new Date(todo.due) : null);
  const formatDue = useMemo(() => (due ? friendlyDateFormat(due) : ''), [due]);
  const formatDueClassName = useMemo(() => {
    const now = new Date();
    let className = 'bg-gray-100 text-gray-500';
    if (!due) {
      className = 'bg-gray-100 text-indigo-600';
    } else if (due <= now) {
      className = 'bg-red-50 text-red-500';
    }
    return className;
  }, [due]);

  const handleSubmit = useCallback(
    (text) => {
      setEditText(text);
      if (text) {
        onSave(text, due);
      } else {
        onDestroy();
      }
    },
    [editable, due, onDestroy, onSave],
  );

  const handleSetDue = useCallback(
    (val: Date) => {
      val.setHours(0, 0, 0, 0);
      onSave(editText, val);
      setDue(val);
    },
    [editText, onSave],
  );

  const handleEdit = useCallback(() => {
    if (editable) {
      isClick.current = true;
      onEdit(index);
      setEditText(todo.title);
    }
  }, [todo.title, index, editable]);

  const handleChange = useCallback(
    (event: React.FormEvent) => {
      if (editable) {
        const input: any = event.target;
        handleSubmit(input.value);
      }
    },
    [editable, handleSubmit],
  );

  const handleKeyDown = useCallback(
    (event: React.KeyboardEvent) => {
      switch (event.key) {
        case ESCAPE_KEY:
          setEditText(todo.title);
          onCancel(event);
          break;
        case ENTER_KEY:
          event.preventDefault();
          event.stopPropagation();
          if (!!editText.length) {
            onAddActionItem();
          }
          break;
        case DELETE:
          if (!editText.length) {
            onDestroy();
          }
          break;
        case ARROW_DOWN:
          event.preventDefault();
          onEdit(index + 1);
          break;
        case ARROW_UP:
          event.preventDefault();
          if (index > 0) {
            onEdit(index - 1);
          }
          break;
        default:
          break;
      }
    },
    [todo.title, editText, index, onEdit, onAddActionItem, onDestroy],
  );

  const handleOpenDataPicker = useCallback(
    (open) => {
      if (dueDateSetable) {
        if (open) {
          Analytics.track(EventTypes.actionItemDueDatePickerOpened);
        }
      }
    },
    [dueDateSetable],
  );

  useEffect(() => {
    if (editing) {
      editField.current.focus();
      if (!isClick.current) {
        editField.current.selectionStart = editField.current.value.length;
        editField.current.selectionEnd = editField.current.value.length;
      }
      isClick.current = false;
    }
  }, [editing]);

  useEffect(() => {
    setEditText(todo.title);
  }, [todo.title]);

  type DateInputProps = {
    onClick?: () => void;
  };

  const CustomDateInput = forwardRef<HTMLInputElement, DateInputProps>(
    ({ onClick }, ref: LegacyRef<HTMLButtonElement>) => (
      <div className={classNames('block', 'flex items-end')}>
        <button
          className={classNames(
            formatDueClassName,
            dueDateSetable ? 'cursor-pointer' : 'cursor-default',
            'rounded-xl text-xs border-0 ml-5 py-1 px-3 text-right focus:ring-0 focus:outline-none',
          )}
          onMouseDown={onClick}
          ref={ref}
        >
          <span>{formatDue || 'Select Date'}</span>
        </button>
      </div>
    ),
  );

  return (
    <>
      <div className="relative flex items-start py-1">
        <input
          id={id}
          name="comments"
          type="checkbox"
          checked={todo.completed}
          readOnly={!completable}
          onChange={onToggle}
          data-testid={`action-item-checkbox-${todo.id}`}
          className={classNames(
            'flex-0 h-5 w-5 ring-0.5 text-indigo-200 rounded border-2 pt-0.5',
            todo.completed
              ? 'border-indigo-200 focus:ring-transparent '
              : 'border-indigo-400 focus:ring-transparent ',
          )}
        />
        <div className="flex-auto align-middle">
          <div className="flex ml-2 text-sm w-full content-center">
            {prefix ? (
              <div className="hidden sm:inline-flex">{prefix}</div>
            ) : null}
            {prefix ? (
              <ChevronRightIcon
                className="hidden sm:inline-flex h-3 w-3 text-gray-300 m-1"
                aria-hidden="true"
              />
            ) : null}
            <div className="flex-auto">
              <div className="flex justify-between w-full">
                <TextareaAutosize
                  ref={editField}
                  placeholder="Start typing here…"
                  className={classNames(
                    'text-sm border-0 focus:ring-0 w-full max-w-md resize-none p-0 pb-1 pr-24',
                    editing ? 'cursor-auto' : 'cursor-default',
                    todo.completed && 'text-gray-400',
                  )}
                  value={editText}
                  onChange={(e) => handleChange(e)}
                  onKeyDown={(e) => handleKeyDown(e)}
                  onClick={() => handleEdit()}
                  readOnly={!editable}
                  maxLength={1000}
                  data-testid={`action-item-input-${todo.id}`}
                  onBlur={() => {
                    if (editing) {
                      onEdit(null);
                    }
                  }}
                />
                {showDueDate && (
                  <DatePicker
                    onChange={(newValue) => {
                      handleSetDue(newValue as Date);
                    }}
                    onCalendarClose={() => handleOpenDataPicker(false)}
                    onCalendarOpen={() => handleOpenDataPicker(true)}
                    selected={due}
                    readOnly={!dueDateSetable}
                    wrapperClassName="absolute right-0"
                    customInput={<CustomDateInput />}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ActionItem;
