import React from 'react';
import { ActionItemInterface } from 'app/utils/google';
import { Dispatch, useCallback, useMemo, useState } from 'react';
import { ALL_TODOS, COMPLETED_TODOS, UNCOMPLETED_TODOS } from '../constants';
import ActionItem from '../elements/ActionItem';
import { ITodo } from '../interfaces/ActionList';
import { Analytics, EventTypes } from 'app/utils/analytics';

import _ from 'lodash';
import Button from '../elements/Button';
import { PlusIcon } from '@heroicons/react/solid';

interface ActionListProps {
  actionItems: ActionItemInterface[];
  setActionItems?: Dispatch<ActionItemInterface[]>;
  canAddNewItem?: boolean;
  editable?: boolean;
  completable?: boolean;
  dueDateSetable?: boolean;
  onlyUnCompleted?: boolean;
  addText?: string;
  showDueDate?: boolean;
  prefix?: (actionItem: ActionItemInterface) => JSX.Element;
}

const ActionList = ({
  actionItems,
  setActionItems,
  canAddNewItem,
  editable,
  completable,
  dueDateSetable,
  onlyUnCompleted,
  showDueDate,
  addText,
  prefix,
}: ActionListProps) => {
  const [editing, setEditing] = useState<number>(null);
  const [nowShowing, _setNowShowing] = useState(
    onlyUnCompleted ? UNCOMPLETED_TODOS : ALL_TODOS,
  );

  const shownActionList = useMemo(
    () =>
      actionItems?.filter((ai) => {
        switch (nowShowing) {
          case UNCOMPLETED_TODOS:
            return !ai.completed;
          case COMPLETED_TODOS:
            return ai.completed;
          default:
            return true;
        }
      }) ?? [],
    [actionItems, nowShowing],
  );

  const cancel = useCallback(() => {
    setEditing(null);
  }, []);

  const edit = useCallback(
    (index: number) => {
      if (index < shownActionList.length && index >= 0) {
        setEditing(index);
      } else if (index === -1) {
        setEditing(null);
      }
    },
    [shownActionList],
  );

  const addActionItem = useCallback(
    (index, addNew = false) => {
      const newIndex = index + 1;
      if (canAddNewItem) {
        if (addNew || newIndex <= actionItems.length) {
          const newActionItems = [...actionItems];
          newActionItems.splice(newIndex, 0, {
            title: '',
            completed: false,
          });
          setActionItems(newActionItems);
        } else {
          setEditing(null);
        }
      }
      setEditing(newIndex);
    },
    [canAddNewItem, setActionItems],
  );

  const toggle = useCallback(
    (todoToToggle: ITodo) => {
      if (completable) {
        Analytics.track(EventTypes.actionItemToggled);

        const newActionItems = actionItems.map<ITodo>((todo: ITodo) => {
          return todo !== todoToToggle
            ? todo
            : { ...todo, completed: !todo.completed };
        });
        setActionItems(newActionItems);
      }
    },
    [completable, setActionItems],
  );

  const save = useCallback(
    (todoToSave: ITodo, text: string, due: Date) => {
      const newActionItems = actionItems.map((todo) => {
        return todo !== todoToSave
          ? todo
          : { ...todo, title: text, due: due?.toUTCString() };
      });
      setActionItems(newActionItems);
    },
    [setActionItems],
  );

  const destroy = useCallback(
    (todoToDestroy: ITodo) => {
      const newActionItems = actionItems.filter((todo) => {
        return todo !== todoToDestroy;
      });
      setActionItems(newActionItems);
      edit(editing - 1);
    },
    [editing, setActionItems, edit],
  );

  const addNewActionItem = useCallback(() => {
    addActionItem(shownActionList.length - 1, true);
  }, [shownActionList, addActionItem]);

  return (
    <div className="space-y-1">
      {!!actionItems?.length && (
        <div className="mt-4">
          {shownActionList.map((sal, index) => (
            <ActionItem
              key={sal.id}
              todo={sal}
              onToggle={() => toggle(sal)}
              onDestroy={() => destroy(sal)}
              onAddActionItem={() => addActionItem(index)}
              onEdit={edit}
              index={index}
              editing={editing === index}
              onSave={(text, due) => save(sal, text, due)}
              onCancel={cancel}
              dueDateSetable={dueDateSetable}
              editable={editable}
              completable={completable}
              prefix={!!prefix ? prefix(sal) : null}
              showDueDate={showDueDate}
            />
          ))}
        </div>
      )}
      {editable && editing === null && (
        <Button
          size="xs"
          type="tertiary"
          leadingIcon={
            <PlusIcon
              className="block h-4 w-4 mr-1.5 text-gray-600"
              aria-hidden="true"
            />
          }
          className="block mt-4"
          onClick={addNewActionItem}
        >
          {addText}
        </Button>
      )}
    </div>
  );
};

export default ActionList;
