const filterByDates = (
  items: [] = [],
  filterDate: string,
  from: Date = new Date(),
  till: Date = new Date(8640000000000000)
) => {
    const fromTime = from.getTime();
    const tillTime = till.getTime();
    if(!items || !items.length) {
      return [];
    }
    const filterItems = items.filter((items) => {
        const filterDateTime = new Date(items[filterDate]).getTime()
        return filterDateTime >= fromTime && filterDateTime < tillTime;
    })
    return filterItems
}

export default filterByDates
