import React from 'react';
import Link from 'next/link';

import { classNames } from 'app/utils/classNames';

interface CustomLinkProps {
  href: string;
  type?: 'base' | 'danger';
  className?: string | undefined;
  children: any;
}

const CustomLink = (props: CustomLinkProps) => {
  const { href, className = '', type = 'base', children } = props;
  const classes = classNames(
    type === 'danger'
      ? 'text-red-300 hover:text-red-400'
      : 'text-indigo-500 hover:text-indigo-700',
    'transition duration-200 text-sm',
    className,
  );
  return (
    <span className={classes}>
      <Link href={href}>{children}</Link>
    </span>
  );
};

export default CustomLink;
