import React, { useCallback, useEffect, useState } from 'react';
import { ActionItemInterface } from 'app/utils/google';
import Link from 'next/link';
import { meetingPath } from 'app/utils/routes';
import truncate from 'truncate';
import { meetingProspectName } from 'app/utils/meeting';
import { classNames } from 'app/utils/classNames';
import { useLazyQuery, useMutation } from '@apollo/client';
import { GET_ACTION_ITEMS } from 'app/api/queries';
import ActionList from '../ActionList';
import { UPDATE_ACTION_ITEMS } from 'app/api/mutations';
import ToastMessage from 'app/utils/toast';
import { ALL, NEXT_WEEK, OVERDUE, WEEK } from 'app/components/constants';
import Button from 'app/components/elements/Button';
import { getTimeFrameRanges } from 'app/utils/timeframeRanges';

interface ActionItemsProps {
  updateMeetingActionItems: (
    actionItems: ActionItemInterface[],
  ) => Promise<void>;
  meetingsActionItems?: ActionItemInterface[];
}

const ActionItems = ({
  updateMeetingActionItems,
  meetingsActionItems,
}: ActionItemsProps) => {
  const [actionItems, setActionItems] = useState<ActionItemInterface[]>();
  const [timeframe, setTimeframe] = useState(OVERDUE);
  const [count, setCount] = useState(0);
  const [showAll, setShowAll] = useState(false);

  const [getActionItems, { error: errorActionItems, data: dataActionItems }] =
    useLazyQuery(GET_ACTION_ITEMS, {
      fetchPolicy: 'network-only',
    });

  const [bulkUpdateActionItems, { error: errorUpdate }] =
    useMutation(UPDATE_ACTION_ITEMS);

  const updateActionItems = useCallback(
    (updatedActionItems: ActionItemInterface[]) => {
      const existingActionItems = getExistingActionItems(
        actionItems,
        updatedActionItems,
      );
      setActionItems(existingActionItems);
    },
    [actionItems],
  );

  const getExistingActionItems = useCallback(
    (
      actionItems: ActionItemInterface[],
      updatedActionItems: ActionItemInterface[],
    ) => {
      return (
        actionItems?.map((ai) => {
          const updatedActionItem =
            updatedActionItems?.find((uai) => uai.id === ai.id) ?? {};
          return { ...ai, ...updatedActionItem };
        }) ?? []
      );
    },
    [],
  );

  const handleSetActionItems = useCallback(
    async (newActionItems: ActionItemInterface[]) => {
      if (!!newActionItems.length) {
        updateActionItems(newActionItems);
        updateMeetingActionItems(newActionItems);
        bulkUpdateActionItems({
          variables: {
            data: newActionItems.map(({ meeting, ...rest }) => rest),
          },
        });
      }
    },
    [updateMeetingActionItems, updateActionItems],
  );

  const actionItemsPrefix = useCallback((actionItem: ActionItemInterface) => {
    if (actionItem.meeting?.userToProspect) {
      return (
        <Link href={meetingPath(actionItem.meeting.slug)}>
          {truncate(meetingProspectName(actionItem.meeting), 20)}
        </Link>
      );
    }
  }, []);

  const handleShowAll = useCallback(() => {
    const range = getTimeFrameRanges(timeframe);
    getActionItems({
      variables: { range, all: timeframe === ALL, showAll: true },
    });
  }, [timeframe]);

  useEffect(() => {
    if (!!errorActionItems) {
      ToastMessage({
        type: 'error',
        message: 'Unfortunately, we could not get action items',
        error: errorActionItems,
      });
    }
    if (!!dataActionItems) {
      setActionItems(dataActionItems.actionItems.data);
      setCount(dataActionItems.actionItems.count);
      setShowAll(
        dataActionItems.actionItems.count ===
          dataActionItems.actionItems.data?.length,
      );
    }
  }, [dataActionItems, errorActionItems]);

  useEffect(() => {
    if (!!errorUpdate) {
      ToastMessage({
        type: 'error',
        message: 'Unfortunately, we could not update action items',
        error: errorUpdate,
      });
    }
  }, [errorUpdate]);

  useEffect(() => {
    updateActionItems(meetingsActionItems);
  }, [meetingsActionItems]);

  useEffect(() => {
    const range = getTimeFrameRanges(timeframe);
    getActionItems({
      variables: { range, all: timeframe === ALL, showAll: false },
    });
  }, [timeframe]);

  return (
    <>
      <h2 className="text-xl font-light leading-tight text-gray-600">
        Action items
      </h2>
      <div className="my-2">
        <div className="flex flex-row">
          <Button
            className="rounded-xl text-sm border-0 py-1 px-3 mr-2 text-center focus:ring-0 focus:outline-none"
            type={timeframe === OVERDUE ? 'primary' : 'medium'}
            onClick={() => setTimeframe(OVERDUE)}
          >
            Overdue
          </Button>
          <Button
            className="rounded-xl text-sm border-0 py-1 px-3 mr-2 text-center focus:ring-0 focus:outline-none"
            type={timeframe === WEEK ? 'primary' : 'medium'}
            onClick={() => setTimeframe(WEEK)}
          >
            This Week
          </Button>
          <Button
            className="rounded-xl text-sm border-0 py-1 px-3 mr-2 text-center focus:ring-0 focus:outline-none"
            type={timeframe === NEXT_WEEK ? 'primary' : 'medium'}
            onClick={() => setTimeframe(NEXT_WEEK)}
          >
            Next Week
          </Button>
          <Button
            className="rounded-xl text-sm border-0 py-1 px-3 mr-2 text-center focus:ring-0 focus:outline-none"
            type={timeframe === ALL ? 'primary' : 'medium'}
            onClick={() => setTimeframe(ALL)}
          >
            All
          </Button>
        </div>
      </div>
      {!!actionItems && !!actionItems.length && (
        <div className="my-3">
          <div className="mt-2">
            <ActionList
              actionItems={actionItems}
              prefix={actionItemsPrefix}
              setActionItems={handleSetActionItems}
              completable
              dueDateSetable
            />
          </div>
        </div>
      )}
      {!actionItems?.length && (
        <div className="mt-4">
          <h3 className="text-base text-gray-300">No action items</h3>
        </div>
      )}
      {!showAll && count > 10 && (
        <div>
          <button
            className="text-sm text-indigo-500 focus:ring-0 focus:outline-none"
            onClick={() => handleShowAll()}
          >
            {'Show more...'}
          </button>
        </div>
      )}
    </>
  );
};

export default ActionItems;
