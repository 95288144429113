import React from 'react';
import Avatar from 'boring-avatars';
import Image from 'next/image';

interface LogoProps {
  logoUrl?: string;
  uniqueName?: string;
  size: number;
}

const Logo = (logoProps: LogoProps) => {
  const { logoUrl, uniqueName, size } = logoProps;

  return !!logoUrl ? (
    <img className="rounded-full" src={logoUrl} width={size} height={size} />
  ) : (
    <Avatar
      size={size}
      name={uniqueName}
      variant="marble"
      colors={['#DADAFF', '#BFFDF1', '#B8DCFF']}
    />
  );
};

export default Logo;
