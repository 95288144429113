import React from 'react';
import truncate from 'truncate';

import { ChevronRightIcon } from '@heroicons/react/outline';

import CustomLink from 'app/components/elements/CustomLink';
import Logo from 'app/components/elements/Logo';
import { MeetingInterface } from 'app/utils/google';
import { friendlyDateFormat } from 'app/utils/stringToFormatDate';
import { meetingProspectName } from 'app/utils/meeting';

interface RecentMeetingsProps {
  meeting: MeetingInterface;
}

const RecentMeetingsNotesItem = ({ meeting }: RecentMeetingsProps) => {
  const prospect = meeting.userToProspect?.prospect;
  return (
    <div className="pt-1 flex space-x-2 items-center">
      <div className="flex-0">
        <Logo
          logoUrl={prospect?.logoUrl}
          uniqueName={meetingProspectName(meeting)}
          size={32}
        />
      </div>

      {!!prospect && (
        <div className="flex-none text-sm">
          {truncate(meetingProspectName(meeting), 20)}
        </div>
      )}

      {!!prospect && (
        <ChevronRightIcon
          className="flex-none h-4 w-4 text-gray-300 mx-1"
          aria-hidden="true"
        />
      )}
      <div className="flex-auto truncate">
        <CustomLink href={`/meetings/${meeting.slug}`}>
          {meeting.meetingName || 'General note'}
        </CustomLink>
      </div>
      <div className="flex-none text-xs text-gray-400 text-right">
        {friendlyDateFormat(meeting.meetingEndsAt)}
      </div>
    </div>
  );
};

export default RecentMeetingsNotesItem;
