import React, {
  Dispatch,
  useCallback,
  useEffect,
  useMemo,
  useRef,
} from 'react';
import _ from 'lodash';

import Button from 'app/components/elements/Button';
import CustomLink from 'app/components/elements/CustomLink';

import { MeetingInterface } from 'app/utils/google';
import { Analytics, EventTypes } from 'app/utils/analytics';

import Logo from 'app/components/elements/Logo';
import {
  meetingDate,
  meetingProspectName,
  meetingTime,
} from 'app/utils/meeting';
import ToastMessage from 'app/utils/toast';
import ActionList from './ActionList';
import { gql, useMutation } from '@apollo/client';
import {
  EXTERNAL_MEETING,
  INTERNAL_MEETING,
  PERSONAL_MEETING,
} from 'app/utils/constants';
import { classNames } from 'app/utils/classNames';

interface MeetingCardProps {
  meeting: MeetingInterface;
  setMeeting: Dispatch<MeetingInterface>;
}

const UPDATE_MEETING = gql`
  mutation UpdateMeeting($slug: String!, $data: MeetingInput!) {
    update(slug: $slug, data: $data) {
      id
    }
  }
`;

const MeetingCard = ({ meeting, setMeeting }: MeetingCardProps) => {
  const [updateMeeting, { error: errorUpdate }] = useMutation(UPDATE_MEETING);

  useEffect(() => {
    if (!!errorUpdate) {
      ToastMessage({
        type: 'error',
        message: 'Could not update the meeting.',
        error: errorUpdate,
      });
    }
  }, [errorUpdate]);

  const formatMeetingDate = useMemo(
    () => meetingDate(meeting),
    [meeting.meetingStartsAt],
  );
  const formatMeetingTime = useMemo(
    () => meetingTime(meeting),
    [meeting.meetingStartsAt],
  );

  const formatAttendees = useMemo(() => {
    const attendeesToMeeting = meeting.attendeesToMeeting;
    if (attendeesToMeeting?.length) {
      let statusComponents: string[] = [];
      const confirmedCount = attendeesToMeeting.filter(
        (assistant) => assistant.status === 'accepted',
      ).length;

      if (!!confirmedCount) {
        statusComponents.push(`${confirmedCount} confirmed`);
      }

      const declinedCount = attendeesToMeeting.filter(
        (assistant) => assistant.status === 'declined',
      ).length;
      if (!!declinedCount) {
        statusComponents.push(`${declinedCount} declined`);
      }

      const unconfirmedCount = attendeesToMeeting.filter(
        (assistant) => assistant.status === 'needsAction',
      ).length;
      if (!!unconfirmedCount) {
        statusComponents.push(`${unconfirmedCount} unconfirmed`);
      }

      return `Attendees: ${statusComponents.join(', ')}`;
    }
    return '';
  }, [meeting.attendeesToMeeting]);

  const formatActionItems = useMemo(() => {
    if (!!meeting.actionItems?.length) {
      return `Outstanding action items:`;
    }
    return null;
  }, [meeting.actionItems]);

  const meetingLink = `/meetings/${meeting.slug}`;

  const handleUpdateMeeting = useCallback(async (meeting) => {
    updateMeeting({
      variables: {
        slug: meeting.slug,
        data: {
          meetingName: meeting.meetingName,
          meetingStartsAt: meeting.meetingStartsAt,
          meetingEndsAt: meeting.meetingEndsAt,
          freeFormNotes: meeting.freeFormNotes,
          structuredNotes: meeting.structuredNotes,
          actionItems: meeting.actionItems,
          agenda: meeting.agenda,
        },
      },
    });
  }, []);

  const debouncedUpdateMeeting = useRef(
    _.debounce((meeting) => handleUpdateMeeting(meeting), 500),
  ).current;

  const handleSetMeeting = useCallback(
    (newMeeting) => {
      setMeeting(newMeeting);
      debouncedUpdateMeeting(newMeeting);
    },
    [setMeeting],
  );

  const setActionItems = useCallback(
    (newActionItems) => {
      const oldActionItems = meeting.actionItems;
      const updatedActionItems = oldActionItems.map((oai) => {
        const newActionItem = newActionItems.find((nai) => nai.id === oai.id);
        return newActionItem ?? oai;
      });
      const newMeeting = { ...meeting, actionItems: updatedActionItems };
      if (!_.isEqual(newMeeting, meeting)) {
        handleSetMeeting(newMeeting);
      }
    },
    [meeting, handleSetMeeting],
  );

  const classifyComponent = useMemo(() => {
    let className;
    switch (meeting.classify) {
      case INTERNAL_MEETING:
        className = 'bg-blue-100';
        break;
      case EXTERNAL_MEETING:
        className = 'bg-green-100';
        break;
      case PERSONAL_MEETING:
        className = 'bg-gray-100';
        break;
      default:
        break;
    }
    return (
      <div className="flex items-end">
        <button
          className={classNames(
            className,
            'cursor-default text-gray-500 rounded-xl text-xs border-0 ml-2 py-1 px-3 text-right focus:ring-0 focus:outline-none',
          )}
        >
          <span className="text-sm font-normal">{meeting.classify}</span>
        </button>
      </div>
    );
  }, [meeting]);

  return (
    <div className="bg-white border border-gray-200 shadow-sm rounded-lg overflow-hidden divide-y divide-gray-100">
      <div className="px-4 py-4">
        <div className="flex space-x-4 items-center">
          <div className="flex-auto">
            <div className="flex items-center">
              <Logo
                size={32}
                logoUrl={meeting.userToProspect?.prospect?.logoUrl}
                uniqueName={meetingProspectName(meeting)}
              />
              {!!meeting.userToProspect?.prospect && (
                <h3 className="ml-2 flex-0 text-sm font-normal text-gray-500">
                  {meetingProspectName(meeting)}
                </h3>
              )}
              {classifyComponent}
            </div>
          </div>
          <div className="flex-auto text-sm text-gray-400 text-right">
            <span className="">{formatMeetingDate}</span>
            <span className="px-1"> • </span>
            <span className="">{formatMeetingTime}</span>
          </div>
        </div>

        <div className="mt-4">
          <CustomLink href={meetingLink} className="text-base font-normal">
            {meeting.meetingName || 'General meeting'}
          </CustomLink>
        </div>

        {meeting.actionItems?.length > 0 && (
          <div className="mt-4">
            <div className="text-sm text-gray-400">{formatActionItems}</div>
            <div className="my-2">
              <ActionList
                actionItems={meeting.actionItems}
                setActionItems={setActionItems}
                completable
              />
            </div>
          </div>
        )}
      </div>
      {meeting.classify !== PERSONAL_MEETING && (
        <div className="px-3 py-2 flex items-center">
          {/* <div className="flex-1">
          <button
            type="button"
            className="bg-white rounded-md text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
            onClick={() => false}
          >
            <span className="sr-only">Close</span>
            <XIcon className="h-6 w-6" aria-hidden="true" />
          </button>
        </div> */}

          <div className="flex-1">
            <div className="flex">
              <div className="flex-auto">
                <a
                  className="text-sm text-gray-400 hover:text-gray-500"
                  href={meetingLink}
                >
                  {formatAttendees}
                </a>
              </div>
              <div className="flex-auto">
                <div className="flex flex-row-reverse space-x">
                  <Button
                    buttonType="link"
                    size="xs"
                    type="secondary"
                    className="ml-2"
                    href={meetingLink}
                    onClick={() => {
                      Analytics.track(EventTypes.meetingCardViewNotesCLick, {});
                    }}
                  >
                    View notes
                  </Button>

                  {!!meeting.videoLink && (
                    <Button
                      buttonType="link"
                      size="xs"
                      type="tertiary"
                      href={meeting.videoLink}
                      blank
                      onClick={() => {
                        Analytics.track(
                          EventTypes.meetingCardJoinMeetingCLick,
                          {},
                        );
                      }}
                    >
                      Join meeting
                    </Button>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default MeetingCard;
