import React, { useCallback, useEffect, useMemo, useState } from 'react';
import getConfig from 'next/config';
import Head from 'next/head';

import CustomLink from 'app/components/elements/CustomLink';
import AuthorizedLayout from 'app/components/layouts/AuthorizedLayout';
import MeetingCard from 'app/components/modules/MeetingCard';
import RecentMeetingItem from 'app/components/modules/RecentMeetingNotes/Item';
import { ActionItemInterface, MeetingInterface } from 'app/utils/google';
import filterByDates from 'app/utils/filterByDates';
import ToastMessage from 'app/utils/toast';
import { useLazyQuery, useQuery } from '@apollo/client';
import { EventTypes, Analytics } from 'app/utils/analytics';
import timeout from 'app/utils/timeout';
import {
  EXTERNAL_MEETING,
  INTERNAL_MEETING,
  ONBOARDING,
  PERSONAL_MEETING,
} from 'app/utils/constants';
import {
  GET_MEETINGS,
  GET_RECENT_MEETINGS,
  SYNC_MEETINGS,
} from 'app/api/queries';
import ActionItems from 'app/components/modules/Dashboard/ActionItems';
import { useSessionStorage } from 'app/utils/hooks/';
import { useRouter } from 'next/router';

const { publicRuntimeConfig } = getConfig();

const DEFAULT_TIME_OUT_GET_MEETINGS = 300000;

export default function Dashboard() {
  const router = useRouter();
  const { origin } = router.query;

  const timeOut =
    parseInt(publicRuntimeConfig.timeOutGetMeetings || '') ||
    DEFAULT_TIME_OUT_GET_MEETINGS;

  const meetingFilterConfig = [
    { filterName: 'external', filterTitle: 'External' },
    { filterName: 'internal', filterTitle: 'Internal' },
    { filterName: 'personal', filterTitle: 'Personal' },
  ];
  const [filterMeetings, setFilterMeetings] = useSessionStorage(
    'filterMeetings',
    {
      external: true,
      internal: true,
      personal: true,
    },
  );

  const [meetings, setMeetings] = useState<MeetingInterface[]>();
  const [filteredMeetings, setFilteredMeetings] =
    useState<MeetingInterface[]>();
  const [recentMeetings, setRecentMeetings] = useState<MeetingInterface[]>();

  const [sync] = useLazyQuery(SYNC_MEETINGS, {
    pollInterval: timeOut,
    fetchPolicy: 'network-only',
    onCompleted: () => getMeetings(),
    onError: () => getMeetings(),
    notifyOnNetworkStatusChange: true,
  });

  const [getMeetings, { error: errorMeetings, data: dataMeetings }] =
    useLazyQuery(GET_MEETINGS, {
      fetchPolicy: 'network-only',
    });

  const { error: errorRecentMeetings, data: dataRecentMeetings } = useQuery(
    GET_RECENT_MEETINGS,
    { fetchPolicy: 'network-only' },
  );

  useEffect(() => {
    if (!!errorMeetings) {
      ToastMessage({
        type: 'error',
        message: 'Unfortunately, we could not get upcoming meetings',
        error: errorMeetings,
      });
    }
    if (!!dataMeetings) {
      const classifiedMeetings = classifyMeetings(dataMeetings.meetings);
      setMeetings(classifiedMeetings);
    }
  }, [dataMeetings, errorMeetings]);

  useEffect(() => {
    if (!!errorRecentMeetings) {
      ToastMessage({
        type: 'error',
        message: 'Unfortunately, we could not get recent meetings',
        error: errorRecentMeetings,
      });
    }
    if (!!dataRecentMeetings) {
      setRecentMeetings(dataRecentMeetings.recent);
    }
  }, [dataRecentMeetings, errorRecentMeetings]);

  useEffect(() => {
    Analytics.track(EventTypes.pageView, { page_type: 'dashboard' });
  }, []);

  useEffect(() => {
    handleGetMeetings(origin);
  }, [origin]);

  useEffect(() => {
    if (meetings) {
      const newFilteredMeetings = meetings.filter((meeting) => {
        let isValid = true;
        if (!filterMeetings.external) {
          isValid = meeting.classify !== EXTERNAL_MEETING;
        }
        if (!filterMeetings.internal) {
          isValid = isValid && meeting.classify !== INTERNAL_MEETING;
        }
        if (!filterMeetings.personal) {
          isValid = isValid && meeting.classify !== PERSONAL_MEETING;
        }
        return isValid;
      });
      setFilteredMeetings(newFilteredMeetings);
    }
  }, [filterMeetings, meetings]);

  const todayMeetings = useMemo(() => {
    if (filteredMeetings) {
      const tomorrow = new Date();
      tomorrow.setDate(tomorrow.getDate() + 1);
      tomorrow.setHours(0, 0, 0, 0);
      return filterByDates(
        filteredMeetings as [],
        'meetingEndsAt',
        new Date(),
        tomorrow,
      ) as MeetingInterface[];
    }
    return null;
  }, [filteredMeetings]);

  const tomorrowMeetings = useMemo(() => {
    if (filteredMeetings) {
      const tomorrow = new Date();
      tomorrow.setDate(tomorrow.getDate() + 1);
      tomorrow.setHours(0, 0, 0, 0);
      const dayAfterTomorrow = new Date(tomorrow);
      dayAfterTomorrow.setDate(dayAfterTomorrow.getDate() + 1);
      dayAfterTomorrow.setHours(0, 0, 0, 0);
      return filterByDates(
        filteredMeetings as [],
        'meetingStartsAt',
        tomorrow,
        dayAfterTomorrow,
      ) as MeetingInterface[];
    }
    return null;
  }, [filteredMeetings]);

  const meetingsActionItems = useMemo(() => {
    if (!!meetings) {
      const newMeetingsActionItems: ActionItemInterface[] = [];
      meetings.forEach((meeting) =>
        newMeetingsActionItems.push(...meeting.actionItems),
      );
      return newMeetingsActionItems;
    }
    return null;
  }, [meetings]);

  const handleSetMeetings = useCallback(
    (updatedMeeting: MeetingInterface) => {
      setMeetings(
        meetings.map((meeting) =>
          meeting.id === updatedMeeting.id ? updatedMeeting : meeting,
        ),
      );
    },
    [meetings],
  );

  const setActionItems = useCallback(
    async (newActionItems: ActionItemInterface[]) => {
      if (!!newActionItems.length) {
        if (!!meetings?.length) {
          setMeetings(
            meetings.map((meeting) => {
              const actionItems = meeting.actionItems?.map((ai) => {
                const updatedActionItem =
                  newActionItems.find((uai) => uai.id === ai.id) ?? {};
                return { ...ai, ...updatedActionItem };
              });
              return { ...meeting, actionItems };
            }),
          );
        }
      }
    },
    [meetings],
  );

  const classifyMeetings = useCallback((meetings: MeetingInterface[]) => {
    return meetings.map((meeting) => {
      let classify = EXTERNAL_MEETING;

      if (meeting.attendeesToMeeting?.length === 0) {
        classify = PERSONAL_MEETING;
      } else if (meeting.attendeesToMeeting?.every((ma) => ma.myTeam)) {
        classify = INTERNAL_MEETING;
      }
      return { ...meeting, classify };
    });
  }, []);

  const handleGetMeetings = useCallback(async (origin) => {
    if (origin === ONBOARDING) {
      getMeetings();
      await timeout(timeOut);
    }
    sync();
  }, []);

  return (
    <AuthorizedLayout>
      <Head>
        <title>Dashboard - Dealnotes</title>
      </Head>
      <div className="mt-0 grid grid-cols-1 lg:grid-cols-2 gap-10 xl:mt-0 xl:col-span-2">
        <div className="space-y-8">
          {!!todayMeetings && (
            <div>
              <h2 className="text-xl font-light leading-tight text-gray-600">
                Today's summary
              </h2>
              <div className="my-4 flex">
                {meetingFilterConfig.map(({ filterName, filterTitle }) => (
                  <div className="relative flex items-start mr-4">
                    <div className="flex items-center h-5">
                      <input
                        id={filterName}
                        aria-describedby={`${filterName}-description`}
                        name={filterName}
                        type="checkbox"
                        checked={filterMeetings[filterName]}
                        onChange={(event) =>
                          setFilterMeetings({
                            ...filterMeetings,
                            [filterName]: event.target.checked,
                          })
                        }
                        className="focus:ring-transparent h-4 w-4 text-indigo-600 border-gray-300 checked:border-indigo-600 rounded"
                      />
                    </div>
                    <div className="ml-1.5 text-sm">
                      <label
                        htmlFor={filterName}
                        className="font-medium text-gray-700"
                      >
                        {filterTitle}
                      </label>
                    </div>
                  </div>
                ))}
              </div>
              <div className="mt-2 space-y-4">
                {todayMeetings.map((meeting) => (
                  <MeetingCard
                    key={meeting.id}
                    meeting={meeting}
                    setMeeting={handleSetMeetings}
                  />
                ))}
                {!todayMeetings?.length && (
                  <div className="mt-4">
                    <h3 className="text-base text-gray-300">
                      No upcoming meetings
                    </h3>
                  </div>
                )}
              </div>
            </div>
          )}
          {!!tomorrowMeetings && !!tomorrowMeetings.length && (
            <div className="">
              <h2 className="text-xl font-light leading-tight text-gray-500">
                Tomorrow's summary
              </h2>
              <div className="mt-2 space-y-5">
                {tomorrowMeetings.map((meeting) => (
                  <MeetingCard
                    key={meeting.id}
                    meeting={meeting}
                    setMeeting={handleSetMeetings}
                  />
                ))}
              </div>
            </div>
          )}
        </div>
        <div className="">
          <ActionItems
            updateMeetingActionItems={setActionItems}
            meetingsActionItems={meetingsActionItems}
          ></ActionItems>
          <div className="mt-12">
            {/* <div className="w-full border-t border-gray-100" /> */}
            <div className="flex">
              <h2 className="flex-auto text-xl font-light leading-tight text-gray-600">
                Recent meeting notes
              </h2>
              <div className="flex-0">
                <CustomLink href="/prospects" className="text-sm">
                  View all
                </CustomLink>
              </div>
            </div>

            <dl className="mt-3 space-y-1 divide-y divide-gray-50">
              {!!recentMeetings &&
                !!recentMeetings.length &&
                recentMeetings.map((rm) => (
                  <RecentMeetingItem key={rm.id} meeting={rm} />
                ))}
              {!recentMeetings?.length && (
                <h3 className="text-base text-gray-300">No recent meetings</h3>
              )}
            </dl>
          </div>
        </div>
      </div>
    </AuthorizedLayout>
  );
}

Dashboard.auth = true;

// export async function getServerSideProps(ctx: GetServerSidePropsContext) {
//   const session = await getSession(ctx)

//   const credentials = getCredentials();
//   const oAuth2Client = getAuthClient(credentials);

//   oAuth2Client.setCredentials({
//     access_token: session?.accessToken as string,
//     refresh_token: session?.refreshToken as string
//   });

//   const events = await fetchCalendarEvents(oAuth2Client);

//   return {
//     props: {
//       user: session?.user,
//       events
//     },
//   }
// }

export const getInitialProps = () => {};
