import { Meeting } from 'app/entity/Meeting';
import { MeetingInterface, UserToProspectInterface } from './google';

export const meetingProspectName = (meeting: Meeting | MeetingInterface) => {
  return (
    meeting.userToProspect?.displayName ||
    meeting.userToProspect?.prospect?.name ||
    meeting.userToProspect?.prospect?.websiteDomain ||
    'Unnamed prospect'
  );
};

export const userToProspectName = (userToProspect: UserToProspectInterface) => {
  return (
    userToProspect?.displayName ||
    userToProspect?.prospect?.name ||
    userToProspect?.prospect?.websiteDomain ||
    'Unnamed prospect'
  );
};

export const attendeeAvatarUrl = (logoUrl: string | undefined): string => {
  return logoUrl ?? '/images/company-logo-placeholder.svg';
};

export const meetingDate = (meeting: Meeting | MeetingInterface): string => {
  return new Date(meeting.meetingStartsAt).toLocaleString('en-US', {
    weekday: 'short', // long, short, narrow
    day: 'numeric', // numeric, 2-digit
    // year: 'numeric', // numeric, 2-digit
    month: 'long', // numeric, 2-digit, long, short, narrow
  });
};

export const meetingTime = (meeting: Meeting | MeetingInterface): string => {
  const startTime = new Date(meeting.meetingStartsAt).toLocaleString('en-US', {
    hour: 'numeric', // numeric, 2-digit
    minute: 'numeric', // numeric, 2-digit,
  });
  const endTime = new Date(meeting.meetingEndsAt).toLocaleString('en-US', {
    hour: 'numeric', // numeric, 2-digit
    minute: 'numeric', // numeric, 2-digit
  });

  return `${startTime} -> ${endTime}`;
};
