const ALL_TODOS = 'all';
const COMPLETED_TODOS = 'completed';
const UNCOMPLETED_TODOS = 'uncompleted';
const ENTER_KEY = 'Enter';
const DELETE = 'Backspace';
const ESCAPE_KEY = 'Escape';
const ARROW_DOWN = 'ArrowDown';
const ARROW_UP = 'ArrowUp';

export {
  ALL_TODOS,
  UNCOMPLETED_TODOS,
  COMPLETED_TODOS,
  ENTER_KEY,
  ESCAPE_KEY,
  DELETE,
  ARROW_DOWN,
  ARROW_UP,
};
