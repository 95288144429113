import { addDays, subDays, nextMonday } from 'date-fns';
import { NEXT_WEEK, OVERDUE, WEEK } from '../components/constants';

export const getTimeFrameRanges = (timeFrame: string) => {
  let ranges: { from?: Date; to?: Date } = {};
  switch (timeFrame) {
    case OVERDUE:
      ranges.to = new Date();
      break;
    case WEEK: {
      ranges = getWeekLimits();
      break;
    }
    case NEXT_WEEK: {
      const nextMondayDate = nextMonday(new Date());
      nextMondayDate.setHours(0, 0, 0, 0);
      ranges = getWeekLimits(nextMondayDate);
      break;
    }
    default:
      ranges.from = subDays(new Date(), 30);
      ranges.to = addDays(new Date(), 30);
      break;
  }
  return ranges;
};

export const getWeekLimits = (today: Date = new Date()) => {
  const nextWeekend = new Date(today);
  const daysLessWeek = 7 - today.getDay();
  nextWeekend.setDate(nextWeekend.getDate() + daysLessWeek + 1);
  nextWeekend.setHours(0, 0, 0, 0);
  return { from: today, to: nextWeekend };
};
